<template>
	<Modal width="800px">
		<template #modal-title>
			Link Fields
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</IconButton>
		</template>
		<template #modal-content>
			<p>
				Link Fields are used to both identify and pass in information about a Data Subject
			</p>
			<p>
				<b>
					Identification Field
				</b> - This determines whether this field is used to identify if the Data Subject already exists in Cassie.
			</p>
			<p>
				<b>
					Database Field Name
				</b> - The name of the Cassie Database field.
			</p>
			<p>
				<b>
					Name in Link
				</b> - The name of the parameter in the Link.
			</p>
			<p>
				<b>
					Replace Value in Link
				</b> - The string of text which will be replaced by Data Subject details to make the Link unique.
			</p>
			<p>
				<b>
					Encrypted
				</b> - Enabling encryption allows for any sensitive data to be encrypted and decrypted by Cassie once the Link is clicked.  Encryption keys need to be in AES Encrypt format.
			</p>
			<p>
				<b>
					Preview Value
				</b> - This is an optional field whereby if populated with sample/test data, the ability to preview this Link from within the Admin Portal is enabled.  *Please Note, all actions will update the matched default record.*
			</p>
			<p>
				<b>
					Action
				</b> - The Action section gives the ability to delete a Link field
			</p>
		</template>
		<template #modal-footer>
			<v-spacer />
			<SecondaryActionButton @click="$emit('close')">
				Close
			</SecondaryActionButton>
		</template>
	</Modal>
</template>

<script>
import Modal from '../../../../../shared/components/modal.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
export default {
	components: {
		Modal,
		IconButton,
		SecondaryActionButton
	}
}
</script>
