<template>
	<validation-observer #default="{ handleSubmit }">
		<Modal width="500px">
			<template #modal-title>
				Add Encryption Key
			</template>
			<template #modal-content>
				<div class="cassie-vertical-md">
					<TextField
						v-model="keyName"
						label="Encryption Key Name *"
						rules="required"
					/>
					<TextField
						v-model="keyValue"
						label="Encryption Key Value *"
						rules="required"
					/>
				</div>
			</template>
			<template #modal-footer>
				<SecondaryActionButton @click="close">
					Cancel
				</SecondaryActionButton>
				<PrimaryActionButton @click="handleSubmit(saveKey)">
					Save
				</PrimaryActionButton>
			</template>
		</Modal>
	</validation-observer>
</template>

<script>
import Modal from './modal.vue'
import TextField from './text-field.vue'
import PrimaryActionButton from './primary-action-button.vue'
import SecondaryActionButton from './secondary-action-button.vue'
import { saveEncryptionKey } from '../utils/api/admin-portal/links/links.js'
import { showSnackbar } from '..//state/snackbar.js'
export default {
	components: { SecondaryActionButton, PrimaryActionButton, TextField, Modal },
	data () {
		return {
			keyName: '',
			keyValue: ''
		}
	},
	methods: {
		close () {
			this.$emit('close')
		},
		async saveKey () {
			const encryptionKey = {
				encryptionKeyName: this.keyName,
				encryptionKey: this.keyValue
			}
			await saveEncryptionKey(encryptionKey)
			showSnackbar('Encryption Key added')
			this.$emit('encryption-key-added')
			this.close()
		}
	}
}
</script>
