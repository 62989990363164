<template>
	<SectionCard>
		<template #title>
			Authentication
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<Dropdown
						:validate-on-show="formHasPii && isEdit"
						label="Authentication Type *"
						test-id="preference_page_authenticationentication_type"
						:items="authTypes"
						:disabled="!userFullPermissions"
						:value.sync="authenticationDetails.authTypeId"
						:rules="validationRules"
						@change="updateAuthenticationDetails('authTypeId', $event)"
					/>
				</v-col>
				<v-col cols="6">
					<p>
						{{ authTypeTooltip }}
					</p>
				</v-col>
			</v-row>
			<template v-if="authenticationDetails.authTypeId && authenticationDetails.authTypeId !== AUTHENTICATION_TYPES.DIRECT">
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							:value.sync="authenticationDetails.themeId"
							:label="`Theme *`"
							:items="themes"
							:disabled="!userFullPermissions"
							:rules="{required: authenticationDetails.authTypeId !== AUTHENTICATION_TYPES.DIRECT}"
							@change="updateAuthenticationDetails('themeId', $event)"
						/>
					</v-col>
					<v-col cols="6">
						<p>
							Select the theme that you wish to apply
						</p>
					</v-col>
				</v-row>
				<v-row
					v-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL"
					dense
				>
					<v-col cols="6">
						<Dropdown
							label="Proof Email *"
							test-id="preference_page_proof_email"
							rules="required"
							:items="proveConfirmationEmails"
							tooltip-text="The Email that will be sent to the Data Subject as part of the 'Prove Email' or 'Provide & Prove Email' process"
							:disabled="!userFullPermissions"
							:value.sync="authenticationDetails.proveConfirmationEmailId"
							@change="updateAuthenticationDetails('proveConfirmationEmailId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<TextArea
							label="Authentication Area Text *"
							test-id="preference_page_authentication_area_text"
							:rules="{required: true, max: 2000}"
							:disabled="!userFullPermissions"
							tooltip-text="The text displayed to the Data Subject at the top of the Authentication page"
							:value.sync="authenticationDetails.verificationAreaText"
							@input="updateAuthenticationDetails('verificationAreaText', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col
						v-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.CONFIRM_VALUE"
						cols="12"
					>
						<TextArea
							label="Authentication Failed Message *"
							test-id="preference_page_authentication_failed_message"
							:rules="{required: true, max: 2000}"
							:disabled="!userFullPermissions"
							tooltip-text="The text displayed to the Data Subject if the data input leads to Authentication failure"
							:value.sync="authenticationDetails.verificationFailedMessage"
							@input="updateAuthenticationDetails('verificationFailedMessage', $event)"
						/>
					</v-col>
					<v-col
						v-else-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL"
						cols="6"
					>
						<TextField
							label="Authentication Field Label *"
							test-id="preference_page_authentication_field_label"
							:rules="{required: true, max: 50}"
							:disabled="!userFullPermissions"
							tooltip-text="The label text for the Authentication field that the Data Subject must complete to verify their identity"
							:value.sync="authenticationDetails.standDakAuthFieldFriendlyName"
							@input="updateAuthenticationDetails('standDakAuthFieldFriendlyName', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col
						v-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.CONFIRM_VALUE"
						cols="6"
					>
						<Dropdown
							:label="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_EMAIL ? 'Select the Authentication Provide Value Field *' : 'Select the Authentication Confirm Value Field *'"
							:test-id="`preference_page_authentication_${authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_EMAIL ? 'provide' : 'confirm'}_value_field`"
							rules="required"
							:items="mappedStaddakFields"
							tooltip-text="The field that the Data Subject must provide the details for when prompted to access the Preference Page"
							custom-sort
							:disabled="!userFullPermissions"
							:value.sync="authenticationDetails.standDakAuthField"
							@change="updateAuthenticationDetails('standDakAuthField', $event)"
						/>
					</v-col>
					<v-col
						v-else-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL"
						cols="12"
					>
						<TextArea
							label="Authentication Success Message *"
							test-id="preference_page_authentication_success_message"
							:rules="{required: true, max: 2000}"
							:disabled="!userFullPermissions"
							tooltip-text="The text displayed to the Data Subject if this Authentication step has been successful"
							:value.sync="authenticationDetails.verificationSentMessage"
							@input="updateAuthenticationDetails('verificationSentMessage', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col
						v-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.CONFIRM_VALUE"
						cols="6"
					>
						<TextField
							label="Authentication Field Label *"
							test-id="preference_page_authentication_field_label"
							:rules="{required: true, max: 50}"
							tooltip-text="The label text for the Authentication field that the Data Subject must complete to verify their identity"
							:disabled="!userFullPermissions"
							:value.sync="authenticationDetails.standDakAuthFieldFriendlyName"
							@input="updateAuthenticationDetails('standDakAuthFieldFriendlyName', $event)"
						/>
					</v-col>
					<v-col
						v-else-if="authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVE_EMAIL || authenticationDetails.authTypeId === AUTHENTICATION_TYPES.PROVIDE_AND_PROVE_EMAIL"
						cols="12"
					>
						<TextArea
							label="Authentication Failed Message *"
							test-id="preference_page_authentication_failed_message"
							:rules="{required: true, max: 2000}"
							:disabled="!userFullPermissions"
							tooltip-text="The text displayed to the Data Subject if the data input leads to Authentication failure"
							:value.sync="authenticationDetails.verificationFailedMessage"
							@input="updateAuthenticationDetails('verificationFailedMessage', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextArea from '../../../../../../../shared/components/textarea.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import { AUTHENTICATION_TYPES } from '../preference-page-authentication-types.js'
import { getThemes } from '../../../../../../../shared/utils/api/themes.js'

export default {
	components: {
		SectionCard,
		Dropdown,
		TextArea,
		TextField
	},
	props: {
		authenticationDetails: Object,
		userFullPermissions: Boolean,
		proveConfirmationEmails: Array,
		standdakFields: Array,
		formHasPiiFields: Boolean,
		isEdit: Boolean
	},
	data () {
		return {
			AUTHENTICATION_TYPES,
			themes: []
		}
	},
	computed: {
		authTypeTooltip () {
			return this.authTypes.find(({ value }) => value === this.authenticationDetails.authTypeId)?.tooltip
		},
		hideThemeField () {
			return this.authenticationDetails.authTypeId === AUTHENTICATION_TYPES.DIRECT
		},
		formHasPii () {
			return this.formHasPiiFields
		},
		isEditMode () {
			return this.isEdit
		},
		authTypes () {
			return [
				{
					text: 'Direct',
					value: 1,
					tooltip: 'The data subject will not need to be asked to provide any information when clicking on the link to submit preferences. The information contained in the Link Identification Fields is sufficient proof of the Data Subjects identity',
					disabled: this.formHasPii
				},
				{
					text: 'Confirm Value',
					value: 2,
					tooltip: 'The Data Subject will be asked to enter the value held for them in the selected field in Cassie. Access will only be granted if the value matches what is held in Cassie'
				},
				{
					text: 'Prove Email',
					value: 3,
					tooltip: 'The Data Subject will be asked to enter the email address held for them in Cassie and will receive an email containing a verification link. Only when they click on the link contained in that email (proving that they own that email address) will access be granted'
				},
				{
					text: 'Provide Value',
					value: 4,
					tooltip: 'The Data Subject will be asked to confirm their identity by entering a value for them in the selected field. The data collected will be saved against the Data Subjects Personal Information Details'
				},
				{
					text: 'Provide and Prove Email',
					value: 5,
					tooltip: 'The data subject will be asked to provide an Email address. The Email address provided will receive an Email containing a verification link. Only when they click on the link contained in that email (proving that they own that email address) will access be granted'
				}
			]
		},
		validationRules () {
			return {
				required: true,
				isDirectAuthWithPii: { value: (this.formHasPii && this.authenticationDetails.authTypeId === AUTHENTICATION_TYPES.DIRECT) }
			}
		},
		mappedStaddakFields () {
			return this.standdakFields.map(({ text }) => ({
				value: text,
				text: text
			}))
		}
	},
	async created () {
		await this.loadThemes()
	},
	methods: {
		updateAuthenticationDetails (property, value) {
			this.$emit('update:authenticationDetails', {
				...this.authenticationDetails,
				[property]: value
			})
		},
		async loadThemes () {
			const { data: { themes } } = await getThemes()
			this.themes = themes.map(theme => {
				return {
					...theme,
					value: theme.themeId,
					text: theme.themeName
				}
			})
		}
	}
}
</script>
