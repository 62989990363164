<template>
	<SectionCard class="cassie-configure-preference-page__section-card">
		<template #title>
			Behavior
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<Toggle
						:value.sync="configurationOptions.multiStandDakMatchIsFailure"
						test-id="preference_page_multi_stand_dak_match_is_failure"
						label="Treat as failure if link matches more than one data subject in Cassie"
						tooltip-text="If the supplied Authentication & Link fields match multiple Data Subjects, this option will treat the Link as a failure."
						aligned-toggles
						:disabled="!userFullPermissions"
						@update:value="updateConfigurationOptions('multiStandDakMatchIsFailure', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Toggle
						:value.sync="configurationOptions.createStandDakIfNoMatch"
						test-id="preference_page_create_stand_dak_if_no_match"
						label="Create data subject if no match found"
						aligned-toggles
						:disabled="!userFullPermissions"
						tooltip-text="For Links with 'Direct' or 'Provide & Prove' Authentication, this will allow the creation of a new Data Subject if the supplied Authentication & Link fields do not match an existing Data Subject."
						@update:value="updateConfigurationOptions('createStandDakIfNoMatch', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Toggle
						:value.sync="pageSettings.hideCurrentPrefData"
						label="Don't load the data subjects current preferences"
						test-id="preference_page_hide_current_pref_data"
						:disabled="!userFullPermissions"
						:tooltip-text="`
								<p>By default, if a Data Subject accesses this Preference Page, their existing Preferences are displayed.  </p>
								<p>If enabled, this option will default to 'Opt-Out', but will default to 'No Change' if the trinary options are also enabled</p>
								`
						"
						aligned-toggles
						@update:value="updatePageSettings('hideCurrentPrefData', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<Toggle
						:value.sync="configurationOptions.ipLimitations.limitSubmissionsByIpTypeId"
						test-id="preference_page_limit_submissions_by_ip"
						label="Limit the number of times a single IP Address can use a link within a set time period"
						aligned-toggles
						:disabled="!userFullPermissions"
						tooltip-text="This option allows for limiting to be implemented against the Link.  A maximum number of Preference Page submissions within a given time frame in minutes is required."
						@update:value="updateIpLimitations('limitSubmissionsByIpTypeId', $event)"
					/>
				</v-col>
			</v-row>
			<template v-if="configurationOptions.ipLimitations.limitSubmissionsByIpTypeId">
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value.sync="configurationOptions.ipLimitations.noOfSubmissionsAllowedFromIp"
							test-id="preference_page_no_of_submissions_allowed_from_ip"
							label="Number of Allowed Submissions *"
							rules="greaterThanZero"
							type="number"
							:disabled="!userFullPermissions"
							@input="updateIpLimitations('noOfSubmissionsAllowedFromIp', Number($event))"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<TextField
							:value.sync="configurationOptions.ipLimitations.ipLimitPeriodInMins"
							test-id="preference_page_ip_limit_period_in_mins"
							label="Time Period in Minutes *"
							rules="greaterThanZero"
							type="number"
							:disabled="!userFullPermissions"
							@input="updateIpLimitations('ipLimitPeriodInMins', Number($event))"
						/>
					</v-col>
				</v-row>
			</template>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Toggle from '../../../../../../../shared/components/toggle.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'

export default {
	components: {
		SectionCard,
		Toggle,
		TextField
	},
	props: {
		pageSettings: Object,
		configurationOptions: Object,
		userFullPermissions: Boolean
	},
	methods: {
		onValueChange (key, value) {
			this.$emit('update:link-behavior', key, value)
		},
		updateIpLimitations (key, value) {
			const ipLimitations = {
				...this.configurationOptions.ipLimitations,
				[key]: value
			}
			this.updateConfigurationOptions('ipLimitations', ipLimitations)
		},
		updateConfigurationOptions (property, value) {
			this.$emit('update:configurationOptions', {
				...this.configurationOptions,
				[property]: value
			})
		},
		updatePageSettings (property, value) {
			this.$emit('update:pageSettings', {
				...this.pageSettings,
				[property]: value
			})
		}
	}
}
</script>
