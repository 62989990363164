<template>
	<div class="cassie-vertical-md">
		<AuthenticationCard
			:authentication-details.sync="preferencePage.authenticationDetails"
			:user-full-permissions="userFullPermissions"
			:form-has-pii-fields="formHasPiiFields"
			:prove-confirmation-emails="proveConfirmationEmails"
			:is-edit="isEdit"
			:standdak-fields="standdakFields"
		/>
		<PropertyFieldsCard
			:user-full-permissions="userFullPermissions"
			:preference-page.sync="preferencePage"
			:property-fields.sync="preferencePage.propertyFields"
			:page-settings.sync="preferencePage.pageSettings"
			@update-link-text="updatePreferencePage"
		/>
		<LinkFieldsCard
			:link-fields.sync="preferencePage.linkFields"
			:available-fields="standdakFields"
			:user-full-permissions="userFullPermissions"
			@add-link-field="addLinkField"
			@remove-link-field="onRemoveLinkField"
			@update-link-fields-row="updateLinkFieldsRow"
		/>
		<EncryptionKeyCard
			v-if="shouldShowEncryptionKey"
			:encryption-key-id.sync="preferencePage.configurationOptions.encryption.encryptionKeyId"
			:encryption-keys="encryptionKeys"
			:user-full-permissions="userFullPermissions"
			@show-encryption-key-modal="showEncryptionKeyModal = true"
			@update:encryption-key="updateEncryptionKey"
		/>
		<BehaviourCard
			:configuration-options.sync="preferencePage.configurationOptions"
			:user-full-permissions="userFullPermissions"
			:page-settings.sync="preferencePage.pageSettings"
			@update:link-behavior="onDataHasChanged"
		/>
		<AddEncryptionKeyModal
			v-if="showEncryptionKeyModal"
			@close="showEncryptionKeyModal = false"
			@encryption-key-added="$emit('reload-lookup-data')"
		/>
	</div>
</template>

<script>
import AuthenticationCard from './authentication-card.vue'
import LinkFieldsCard from './link-fields-card.vue'
import BehaviourCard from './behaviour-card.vue'
import EncryptionKeyCard from './encryption-key-card.vue'
import PropertyFieldsCard from './property-fields-card.vue'
import AddEncryptionKeyModal from '../../../../../../../shared/components/add-encryption-key-modal.vue'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
export default {
	components: {
		AuthenticationCard,
		LinkFieldsCard,
		BehaviourCard,
		EncryptionKeyCard,
		PropertyFieldsCard,
		AddEncryptionKeyModal
	},
	props: {
		preferencePage: Object,
		userFullPermissions: Boolean,
		proveConfirmationEmails: Array,
		standdakFields: Array,
		selectedEncryptionKey: Number,
		formHasPiiFields: Boolean,
		isEdit: Boolean,
		encryptionKeys: Array
	},
	setup () {
		return {
			showSnackbar
		}
	},
	data () {
		return {
			showEncryptionKeyModal: false
		}
	},
	computed: {
		shouldShowEncryptionKey () {
			return this.preferencePage.linkFields.some(linkField => linkField.isEncrypted)
		}
	},
	methods: {
		addLinkField () {
			const linkFields = [
				...this.preferencePage.linkFields,
				{
					fieldName: '',
					replaceValueInLink: '',
					previewValue: '',
					isEncrypted: false
				}
			]
			this.updatePreferencePage('linkFields', linkFields)
		},
		updateLinkFieldsRow (index, field, value) {
			const linkFields = [...this.preferencePage.linkFields]
			const duplicateLinkField = linkFields.find(({ standdakFieldName }) => standdakFieldName === value && field === 'standdakFieldName')
			if (!duplicateLinkField) {
				linkFields[index][field] = value
				if (field === 'standdakFieldName') {
					linkFields[index].replaceValueInLink = `{${value}}`
				}
				this.updatePreferencePage('linkFields', linkFields)
			} else {
				if (linkFields[index][field] === value) {
					return
				}
				this.showSnackbar({ text: `${value} already exists for this Preference Page - Please select another`, color: 'red' })
				this.onRemoveLinkField(index)
			}
		},
		onRemoveLinkField (index) {
			const linkFields = [...this.preferencePage.linkFields]
			this.$delete(linkFields, index)
			this.updatePreferencePage('linkFields', linkFields)
		},
		onDataHasChanged (key, value) {
			this.updatePreferencePage(key, value)
			this.$emit('update:configuration', key, value)
		},
		updateEncryptionKey (key, value) {
			const configurationOptions = {
				...this.preferencePage.configurationOptions,
				encryption: {
					[key]: value
				}
			}
			this.updatePreferencePage('configurationOptions', configurationOptions)
		},
		updateHideCurrentPrefData (value) {
			const pageSettings = {
				hideCurrentPrefData: value
			}
			this.updatePreferencePage('pageSettings', pageSettings)
		},
		updatePreferencePage (property, value) {
			this.$emit('update:preferencePage', {
				...this.preferencePage,
				[property]: value
			})
		}
	}
}
</script>

<style>
.cassie-configure-preference-page__section-card {
	margin-bottom: 20px;
}

.cassie-configure-preference-page__toggle {
	margin-bottom: 16px;
}
</style>
