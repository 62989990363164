<template>
	<SectionCard class="cassie-configure-preference-page__section-card">
		<template #title>
			Link Properties
		</template>
		<template #body>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value.sync="preferencePage.linkText"
						label="Link Text *"
						test-id="preference_page_link_text"
						:disabled="!userFullPermissions"
						tooltip-text="The Link Text will be visible to the website viewer if this Link is embedded within an Email or Web Page"
						rules="required"
						@input="$emit('update-link-text', 'linkText', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value.sync="propertyFields.sourceCodeReplaceValue"
						label="Source Code Replace Text *"
						test-id="preference_page_source_code_replace_text"
						:disabled="!userFullPermissions"
						:tooltip-text="`
					<p>Input the Source Code replace field text for this Link</p>
					<p>This data will be stored against the Preference update for the Data Subject in question and can be used to determine the source of the transaction if the Link is used in multiple locations</p>
				`"
						:rules="validationRules"
						@input="updatePropertyFields('sourceCodeReplaceValue', $event)"
					/>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col cols="6">
					<TextField
						:value.sync="propertyFields.responseCodeReplaceValue"
						label="Response Channel Replace Text *"
						test-id="preference_page_response_channel_replace_text"
						:disabled="!userFullPermissions"
						:tooltip-text="`
					<p>Input the Response Channel replace field text for this Link</p>
					<p>This data will be stored against the Preference update for the Data Subject in question and can be used to determine the avenue that the transaction took place from e.g. Email, Website Link
</p>
				`"
						:rules="validationRules"
						@input="updatePropertyFields('responseCodeReplaceValue', $event)"
					/>
				</v-col>
			</v-row>
		</template>
	</SectionCard>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'

export default {
	components: {
		SectionCard,
		TextField
	},
	props: {
		preferencePage: Object,
		propertyFields: Object,
		userFullPermissions: Boolean
	},
	computed: {
		validationRules () {
			return {
				required: true,
				forbiddenChars: { value: ['/', '\\', '#'] },
				max: 64
			}
		}
	},
	methods: {
		updatePropertyFields (property, value) {
			this.$emit('update:propertyFields', {
				...this.propertyFields,
				[property]: value
			})
		}
	}
}
</script>
