<template>
	<div
		class="elevation-2 link-viewer__container "
	>
		<Prism
			class="link-viewer__prism"
		>
			{{ embedCodeWithLanguage }}
		</Prism>
		<div class="link-viewer__button-container">
			<SecondaryActionButton
				v-if="showPreviewButton"
				test-id="preference_page_preview"
				@click="openPreview"
			>
				Preview
			</SecondaryActionButton>
			<SecondaryActionButton
				test-id="preference_page_copy_embed_code_urlonly"
				@click="copyToClipboard(false)"
			>
				Copy with URL only
			</SecondaryActionButton>
			<PrimaryActionButton
				test-id="preference_page_copy_embed_code"
				@click="copyToClipboard(true)"
			>
				Copy with tags
			</PrimaryActionButton>
		</div>
	</div>
</template>

<script>
import Prism from 'vue-prism-component'
import PrimaryActionButton from '../../../../../../../shared/components/primary-action-button.vue'
import SecondaryActionButton from '../../../../../../../shared/components/secondary-action-button.vue'
import { showSnackbar } from '../../../../../../../shared/state/snackbar.js'
import { encryptParameters } from '../../../../../../../shared/utils/api/admin-portal/links/links.js'
import { getPreferencePage } from '../../../../../../../shared/utils/api/preference-pages.js'

export default {
	components: {
		Prism,
		PrimaryActionButton,
		SecondaryActionButton
	},
	props: {
		innerValue: {
			type: String,
			required: true
		},
		showPreviewButton: {
			type: Boolean,
			default: false
		},
		preferencePageId: Number,
		selectedLanguageCode: {
			type: String,
			default: 'default'
		}
	},
	setup: () => ({
		showSnackbar
	}),
	computed: {
		embedHref () {
			return new DOMParser()
				.parseFromString(this.embedCodeWithLanguage, 'text/html')
				.getElementsByTagName('a')[0].href
		},
		embedCodeWithLanguage () {
			return this.innerValue.replace(`languagecode=default`, `languagecode=${this.selectedLanguageCode}`)
		}
	},
	async mounted () {
		await this.fetchPrefPageData()
	},
	methods: {
		async fetchPrefPageData () {
			// Fetch the preference page data
			const { preferencePage } = await getPreferencePage(this.preferencePageId)
			this.preferencePage = preferencePage
		},
		copyToClipboard (includeTags) {
			if (includeTags) {
				navigator.clipboard.writeText(this.embedCodeWithLanguage)
			} else {
				const parser = new DOMParser()
				const link = parser.parseFromString(this.embedCodeWithLanguage, 'text/html')
				const href = link.body.getElementsByTagName('a')[0].href
				navigator.clipboard.writeText(href)
					.catch(() => {
						showSnackbar({
							text: 'Error copying to clipboard',
							color: 'red'
						})
					})
			}
		},
		async openPreview () {
			let encryptedParams = {}
			let tempString = this.embedHref
			let hasEncryptedParams = false
			this.preferencePage.linkFields.forEach(field => {
				if (field.isEncrypted && field.previewValue) {
					encryptedParams = { ...encryptedParams, [field.friendlyNameInLink]: field.previewValue }
					hasEncryptedParams = true
				} else {
					tempString = tempString.replace(field.replaceValueInLink, field.previewValue)
				}
			})
			if (!hasEncryptedParams) {
				window.open(tempString)
			} else {
				const { data } = await this.getEncryptedData(encryptedParams, this.preferencePage)
				window.open(tempString.replace('[EncryptedData]', data.encryptedValue))
			}
		},
		async getEncryptedData (encryptedParams, prefPage) {
			return await encryptParameters({
				encryptionKeyId: prefPage.configurationOptions.encryption.encryptionKeyId,
				linkParams: encryptedParams
			})
		}
	}
}
</script>

<style scoped>
.link-viewer__container {
	border-radius: 10px;
	outline: 1px solid #E0E0E0;
}

.link-viewer__prism {
	font-size: 1.3rem;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	margin: 0px;
}

.link-viewer__button-container {
	padding: 0.4rem 8px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	column-gap: 16px;
}
</style>
