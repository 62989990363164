<template>
	<div class="cassie-vertical-md">
		<SectionCard>
			<template #title>
				{{ 'Page Behavior' | useLabels }}
			</template>
			<template #body>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							label="Outcome Success *"
							test-id="preference_page_outcome_success"
							:disabled="!userFullPermissions"
							:tooltip-text="successfulOutcomeTooltip"
							:value.sync="preferencePage.configurationOptions.successOutcome.confirmationPageTypeId"
							:items="successOutcomes"
							rules="required"
							@change="updateSuccessOutcomes('confirmationPageTypeId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="shouldShowSuccessUrl"
					dense
				>
					<v-col cols="6">
						<TextField
							label="Success page URL *"
							test-id="preference_page_success_url"
							:disabled="!userFullPermissions"
							:value.sync="preferencePage.configurationOptions.successOutcome.externalConfirmationPageUrl"
							rules="required"
							reserve-tooltip-space
							@input="updateSuccessOutcomes('externalConfirmationPageUrl', $event)"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="shouldShowConfirmationDropdown"
					dense
				>
					<v-col cols="6">
						<Dropdown
							label="Confirmation Page *"
							test-id="preference_page_confirmation_page"
							:disabled="!userFullPermissions"
							:value.sync="preferencePage.configurationOptions.successOutcome.internalConfirmationPageId"
							:items="confirmationPages"
							reserve-tooltip-space
							rules="required"
							@change="updateSuccessOutcomes('internalConfirmationPageId', $event)"
						/>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<Dropdown
							v-model="failureOutcomeType"
							label="Outcome Failure *"
							test-id="preference_page_outcome_failure"
							:disabled="!userFullPermissions"
							:tooltip-text="failureOutcomeTooltip"
							:items="failureOutcomes"
							rules="required"
							@change="updateFailureOutcomeType($event)"
						/>
					</v-col>
				</v-row>
				<v-row
					v-if="shouldShowFailureUrl"
					dense
				>
					<v-col cols="6">
						<TextField
							label="Failure page URL *"
							test-id="preference_page_failure_url"
							:disabled="!userFullPermissions"
							:value.sync="preferencePage.customErrorUrl"
							reserve-tooltip-space
							rules="required"
							@input="updatePreferencePage('customErrorUrl', $event)"
						/>
					</v-col>
				</v-row>
			</template>
		</SectionCard>
		<ConsentCollectionPrivacyPolicyCard
			:assigned-languages="assignedLanguages"
			:user-full-permissions="userFullPermissions"
			:custom-privacy-policies.sync="preferencePage.customPrivacyPolicies"
			:brand-id.sync="preferencePage.brandId"
			type="preference_page"
			@update-privacy-policies="updatePrivacyPolicies($event)"
			@update-privacy-policies-enabled="updatePrivacyPoliciesEnabled($event)"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import Dropdown from '../../../../../../../shared/components/dropdown.vue'
import TextField from '../../../../../../../shared/components/text-field.vue'
import ConsentCollectionPrivacyPolicyCard from '../../consent-collection-privacy-policy-card.vue'

const outcomes = {
	URL_OUTCOME: 1,
	PAGE_OUTCOME: 2,
	REMAIN_ON_PAGE_OUTCOME: 3, // TBC
	FAILURE_URL: 'Forward To URL'
}

export default {
	components: {
		SectionCard,
		Dropdown,
		TextField,
		ConsentCollectionPrivacyPolicyCard
	},
	props: {
		preferencePage: Object,
		userFullPermissions: Boolean,
		confirmationPages: Array,
		assignedLanguages: Array
	},
	data () {
		return {
			failureOutcomeType: null
		}
	},
	computed: {
		successOutcomes () {
			return [
				{ text: 'Forward to URL', value: outcomes.URL_OUTCOME },
				{ text: 'Forward to Confirmation Page', value: outcomes.PAGE_OUTCOME }
			]
		},
		failureOutcomes () {
			return [
				{ text: 'Forward to URL', value: outcomes.URL_OUTCOME },
				{ text: 'Forward to Failure Page', value: outcomes.PAGE_OUTCOME }
			]
		},
		successfulOutcomeTooltip () {
			switch (this.confirmationPageTypeId) {
				case outcomes.PAGE_OUTCOME:
					return 'Following a successful Preference update, the end user will be redirected to the chosen Confirmation Page'
				case outcomes.URL_OUTCOME:
					return 'Following a successful Preference update, the end user will be redirected to the Destination URL specified.  Note this must begin with http:// or https://'
				case outcomes.REMAIN_ON_PAGE_OUTCOME:
					return `Following a successful Preference update the end user will remain on the page and receive an optional Preference Updated 'success' message confirming the submission has been received.`
				default:
					return 'Select the type of response if the Link update is successful'
			}
		},
		failureOutcomeTooltip () {
			switch (this.selectedFailureOutcomeOption) {
				case outcomes.PAGE_OUTCOME:
					return 'If the update is unsuccessful, the end user will be redirected to a Cassie default error page'
				case outcomes.URL_OUTCOME:
					return 'If the update is unsuccessful, the end user will be redirected to the Destination URL specified.  Note this must begin with http:// or https://'
				case outcomes.REMAIN_ON_PAGE_OUTCOME:
					return 'If the update is unsuccessful, the end user will remain on the page and receiving a plain text failure message.'
				default:
					return 'Select the type of response if the Link update fails'
			}
		},
		shouldShowConfirmationDropdown () {
			return this.preferencePage.configurationOptions.successOutcome.confirmationPageTypeId === outcomes.PAGE_OUTCOME
		},
		shouldShowFailureUrl () {
			return this.failureOutcomeType === outcomes.URL_OUTCOME
		},
		shouldShowSuccessUrl () {
			return this.preferencePage.configurationOptions.successOutcome.confirmationPageTypeId === outcomes.URL_OUTCOME
		}
	},
	created () {
		this.failureOutcomeType = this.preferencePage.customErrorUrl !== null ? outcomes.URL_OUTCOME : outcomes.PAGE_OUTCOME
	},
	methods: {
		updatePreferencePage (property, value) {
			this.$emit('update:preferencePage', {
				...this.preferencePage,
				[property]: value
			})
		},
		updateSuccessOutcomes (property, value) {
			this.$emit('update:preferencePage', {
				...this.preferencePage,
				configurationOptions: {
					...this.preferencePage.configurationOptions,
					successOutcome: {
						...this.preferencePage.configurationOptions.successOutcome,
						[property]: value
					}
				}
			})
		},
		updateFailureOutcomeType (value) {
			if (value === outcomes.PAGE_OUTCOME) {
				this.updatePreferencePage('customErrorUrl', null)
			}
		},
		updatePrivacyPolicies (privacyPolicies) {
			this.updatePreferencePage('customPrivacyPolicies', privacyPolicies)
		},
		updatePrivacyPoliciesEnabled (isEnabled) {
			this.updatePreferencePage('customPrivacyPoliciesEnabled', isEnabled)
		}
	}
}
</script>
