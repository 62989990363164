<template>
	<div>
		<SectionCard class="cassie-configure-preference-page__section-card">
			<template #title>
				Link Fields
				<v-tooltip
					right
				>
					<template #activator="{ on }">
						<v-icon
							right
							v-on="on"
							@click.stop="linkFieldsModalOpen = true"
						>
							mdi-information
						</v-icon>
					</template>
					<span>
						Click to expand
					</span>
				</v-tooltip>
			</template>
			<template #title-action>
				<TextButton
					:disabled="!userFullPermissions"
					@click="addLinkField"
				>
					Add Link Field
				</TextButton>
			</template>
			<template #body>
				<p>
					Select the field in Cassie's database that you wish to store information about the data subject in,
					and whether the information should be used to identify if the data subject already exists in Cassie or not.
				</p>
				<LinkFieldTable
					:link-fields="linkFields"
					:available-fields="availableFields"
					:user-full-permissions="userFullPermissions"
					@remove-link-field="onRemoveLinkField"
					@update-link-fields-row="updateLinkFieldsRow"
				/>
			</template>
		</SectionCard>
		<LinkFieldsHelperModal
			v-if="linkFieldsModalOpen"
			@close="linkFieldsModalOpen = false"
		/>
	</div>
</template>

<script>
import SectionCard from '../../../../../../../shared/components/section-card.vue'
import LinkFieldTable from './link-field-table.vue'
import TextButton from '../../../../../../../shared/components/text-button.vue'
import LinkFieldsHelperModal from '../../../links/link-fields-helper-modal.vue'
export default {
	components: {
		SectionCard,
		LinkFieldTable,
		TextButton,
		LinkFieldsHelperModal
	},
	props: {
		linkFields: Array,
		availableFields: Array,
		userFullPermissions: Boolean
	},
	data () {
		return {
			linkFieldsModalOpen: false
		}
	},
	methods: {
		addLinkField () {
			this.$emit('add-link-field')
		},
		onRemoveLinkField (index) {
			this.$emit('remove-link-field', index)
		},
		updateLinkFieldsRow (index, field, value) {
			this.$emit('update-link-fields-row', index, field, value)
		}
	}
}
</script>
